<template>
  <div
    class="p-3"
    :class="{
      bordered: Object.prototype.hasOwnProperty.call($attrs, 'bordered'),
      stripped: Object.prototype.hasOwnProperty.call($attrs, 'stripped'),
    }"
  >
    <template v-for="field in fields">
      <template v-if="field.type === 'data' || !field.type">
        <tr :key="field.key" :style="field ? null : 'display: none'">
          <template>
            <component
              :is="Object.prototype.hasOwnProperty.call($attrs, 'blockrender') ? 'div' : 'th'"
            >
              <slot :name="`head(${field.key})`" v-bind="field"></slot>
              <template v-if="!$scopedSlots[`head(${field.key})`]">
                {{ field.label }}
              </template>
            </component>
            <component
              :is="Object.prototype.hasOwnProperty.call($attrs, 'blockrender') ? 'div' : 'td'"
            >
              <slot
                :name="`cell(${field.key})`"
                v-bind="{ value: formattedItem[field.key], item: formattedItem, field }"
              ></slot>
              <template v-if="!$scopedSlots[`cell(${field.key})`]">
                {{ formattedItem[field.key] }}
              </template>
            </component>
          </template>
        </tr>
      </template>
      <template v-else-if="field.type === 'other'">
        <slot
          v-bind="{ value: formattedItem[field.key], item: formattedItem, field }"
          :name="`cell(${field.key})-custom`"
        ></slot>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    fields: Array,
  },
  computed: {
    formattedItem() {
      const item = this.lodash.cloneDeep(this.item);
      for (let k = 0; k < this.fields.length; k += 1) {
        const field = this.fields[k];
        if (field && field.formatter)
          item[field.key] = field.formatter(item[field.key], field.key, item);
      }
      return item;
    },
  },
  mounted() {
    console.log(this.formattedItem);
  },
};
</script>

<style lang="scss" scoped>
.bordered:first-child {
  border-top: 1px solid #dee2e6;
}
.bordered {
  border-bottom: 1px solid #dee2e6;
}
.stripped:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
